<template>
  <div ref="body">
    <custom-header active="global" :subActive="current"></custom-header>

    <div class="content-container">
      
      <!-- 交通指引 -->
      <div class="section">
        <h1>Getting to the Fair</h1>
        
        <!-- <div class="tips">Shenzhen World is located near the Shenzhen Bao’an International Airport, the Shenzhen Airport Ferry Terminal, the S3 Expressway (Guangzhou–Shenzhen Riverside Expressway), and Fuhai West Railway Station. It’s also directly connected with metro stations, making it convenient to reach.</div> -->
        
        <div class="end-row ">
          <div class="title text-bold">Venue address:</div>
          <div class="">
            Shenzhen World Exhibition & Convention Center, No. 1 Zhancheng Road, Fuhai Street, Bao 'an District, Shenzhen.
          </div>
          <div class="">
            深圳国际会展中心位于深圳市宝安区福海街道展城路1号
          </div>
        </div>
        
        <div class="end-row ">
          <span class="title text-bold">Air:</span>
          <span class="">
            Shenzhen World is located directly north of the Shenzhen Bao'an International Airport, about 7 km from the Shenzhen Airport T3 Terminal and only 3 km from the T4 hub, also neighboring Hong Kong Airport.
          </span>
        </div>
        
        <div class="end-row ">
          <span class="title text-bold">Ferry:</span>
          <span class="">
            Only 40 minutes are needed to go to Hong Kong Airport from the nearby Shenzhen Airport Ferry Terminal.
          </span>
        </div>
        <div class="end-row ">
          <span class="title text-bold">Road:</span>
          <span class="">
            Shenzhen World is adjacent to Guangzhou-Shenzhen Coastal Expressway (S3), accessible through Fuhai or Shenzhen World toll stations.
          </span>
        </div>
        <div class="end-row">
          <div>
            <span class="title text-bold">Metro:</span>
            <span class="">
              Quick access to and from Shenzhen’s downtown area and Shenzhen Airport through metro lines 12 and 20, which lead directly to Shenzhen World’s South and North entrance halls.
            </span>
          </div>
          <!-- <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/img/metro_map.png?x-oss-process=image/resize,p_50" alt="" class="metro-map"> -->
        </div>
        <div class="end-row ">
          <span class="title text-bold">Intercity Railway:</span>
          <span class="">
            Shenzhen World is located 1 km from the Guangzhou-Dongguan-Shenzhen railway station Fuhai West.
          </span>
        </div>
        <div class="end-row ">
          <span class="title text-bold">Shuttle Bus:</span>
          <span class="">
            The shuttle buses connect Shenzhen World with Tangwei Station on line 11 and the airport, while traveling to and from nearby metro stations, bus stops, commercial centers, and hotels.
          </span>
        </div>
        
      </div>
      
      <!-- 住宿酒店 -->
      <div class="section">
        <h2>Accommodation</h2>
        
        <div class="end-row ">
          <span class="title text-bold">Official travel agency:</span>
          <span class="">Shenzhen Jielv International Conference and Exhibition Co., Ltd</span>
        </div>
        <div class="end-row ">
          <span class="title text-bold">Services: </span>
          <span class="">
            Flight tickets, hotel reservations, car rentals, business travel, and other exhibition-related business travel solutions.
          </span>
        </div>
        <div class="end-row ">
          <div class="title row text-bold">Contact us:</div>
          <div class="row">Service Hotline 1: +86-755-8288 0090 (Mr. Li)</div>
          <div class="row">Service Hotline 2: +86-755-8288 0055 (Miss Wang)</div>
          <div class="row">Mobile/WeChat ID: 18126464213 (Mr. Li) / 17722570869 (Miss Wang)</div>
          <div class="row">Reservation email: <a href="mailto:SERVICE@BESTMEETINGNET.CN">SERVICE@BESTMEETINGNET.CN</a></div>
          <a href="http://jl.miceclouds.com/en/bookingquery.htm?id=1905" class="button text-bold" target="_blank" rel="nofollow noopener noreferrer">BOOK ONLINE</a>
        </div>
        
      </div>
      
      <div v-if="false" class="flex">
        
        <!-- 陪同翻译服务 -->
        <div class="flex">
          <img src="" alt="">
          <div class="item">
            <h2>Translation Services</h2>
            
            <div class="end-row">
              Shenzhen Creative Week provides high-quality paid translation services for overseas audiences, including but not limited to English, Spanish, Italian, French, Russian, Japanese, Arabic, and other languages.
            </div>
            <div class="end-row ">
              Reservation method: Phone reservation (English translation should be booked at least 24 hours in advance, and translation in other languages should be booked at least 72 hours in advance.)
            </div>
            <div class="end-row ">
              For emergency translation, the actual price of the day will apply.
            </div>
          </div>
        </div>
        
        <!-- 144小时过境免签政策 -->
        <div class="flex">
          <img src="" alt="">
          <div class="section">
            <h2>144-hour Visa-Free Transit Policy</h2>
            
            <div class="end-row">
              Visit <a href="https://english.www.gov.cn/policies/featured/202311/24/content_WS65608167c6d0868f4e8e199f.html" target="_blank" rel="nofollow noopener noreferrer">this page</a>  on the State Council of the People’s Republic of China website to see the requirements and if you are eligible for a 144-hour visa-free transit in Shenzhen.
            </div>
          </div>
        </div>
        
        <!-- 签证申请表、签证须知 -->
        <div class="flex">
          <img src="" alt="">
          <div class="section">
            <h2>Instructions for Applying for a Visa to the People's Republic of China</h2>
            
            <!-- 中国签证申请服务中心 -->
            <div class="row">
              Click on the following links for visa services and application form:
            </div>
            <div class="row">
              <a href="https://www.visaforchina.cn/globle/" target="_blank" rel="nofollow noopener noreferrer">CHINESE VISA APPLICATION SERVICE CENTER</a>
            </div>
            <!-- 外国人签证申请表 -->
            <div class="end-row">
              <a href="https://cova.mfa.gov.cn/qzCoCommonController.do?show&pageId=index&locale=en_US" target="_blank" rel="nofollow noopener noreferrer">VISA APPLICATION FORM FOR FOREIGNERS</a>
            </div>
          </div>
        </div>
        
      </div>
      
      <!-- 陪同翻译服务 -->
      <div class="section">
        <h2>Translation Services</h2>
        
        <div class="end-row">
          Shenzhen Creative Week provides high-quality paid translation services for overseas audiences, including but not limited to English, Spanish, Italian, French, Russian, Japanese, Arabic, and other languages.
        </div>
        <div class="end-row">
          Reservation method: Phone reservation (English translation should be booked at least 24 hours in advance, and translation in other languages should be booked at least 72 hours in advance.)
        </div>
        <div class="end-row">
          For emergency translation, the actual price of the day will apply.
        </div>
      </div>
      
      <!-- 144小时过境免签政策 -->
      <div class="section">
        <h2>144-hour Visa-Free Transit Policy</h2>
        
        <div class="end-row">
          Visit <a href="https://english.www.gov.cn/policies/featured/202311/24/content_WS65608167c6d0868f4e8e199f.html" target="_blank" rel="nofollow noopener noreferrer">this page</a>  on the State Council of the People’s Republic of China website to see the requirements and if you are eligible for a 144-hour visa-free transit in Shenzhen.
        </div>
      </div>
      
      <!-- 签证申请表、签证须知 -->
      <div class="section">
        <h2>Instructions for Applying for a Visa to the People's Republic of China</h2>
        
        <!-- 中国签证申请服务中心 -->
        <div class="row">
          Click on the following links for visa services and application form:
        </div>
        <div class="row">
          <a href="https://www.visaforchina.cn/globle/" target="_blank" rel="nofollow noopener noreferrer">CHINESE VISA APPLICATION SERVICE CENTER</a>
        </div>
        <!-- 外国人签证申请表 -->
        <div class="end-row">
          <a href="https://cova.mfa.gov.cn/qzCoCommonController.do?show&pageId=index&locale=en_US" target="_blank" rel="nofollow noopener noreferrer">VISA APPLICATION FORM FOR FOREIGNERS</a>
        </div>
      </div>
      
      <!-- 中国宣布对五个欧洲国家和马来西亚免签证 -->
      <div class="section">
        <div class="row text-bold text-center">
          <h2>Announcement on Visa-Free Travel for Five European Countries + Malaysia</h2>
        </div>
        <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/img/656078a5a3109068cb007ec5.jpeg" alt="" class="block">
        <p class="end-row end-row1">Visitors line up for check in at Beijing Daxing International Airport. [Photo by Chen Yongmin for chinadailycom.cn]</p>
        <p class="end-row">From December 1, 2024 to November 30, 2025, travelers holding ordinary passports from France, Germany, Italy, the Netherlands, Spain, and Malaysia are able to enter China without a visa and stay for no more than 15 days for business, tourism, family visits, and transit purposes.</p>
        <p class="end-row end-row1">Source: <a href="http://www.chinadaily.com.cn/a/202311/24/WS6560552ea31090682a5eff22.html" target="_blank" rel="noopener noreferrer">China Daily</a></p>
      </div>
      
      <!-- 便利化支付 -->
      <div class="section">
        <h2>Convenient Payment</h2>
        
        <div class="row">
          To improve consumer convenience, exhibitors can download the "WeChat" or "Alipay" apps and link their international cards. By using these apps, they can use mobile payment at most merchants in China, including dining, transportation, hotels, and supermarkets. Currently, major international bank cards like Visa, Mastercard, Diners Club, and Discover are supported. Please refer to the video guide for more detailed information.
        </div>
        <div class="end-row end-row1">(This video is provided by the Shanghai Municipal Foreign Affairs Office.)</div>
        <div class="video-row end-row">
          <div class="video-container">
            <video id="alipay-video" controls class="fill">
              <source src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/img/alipay.mp4" type="video/mp4">
            </video>
          </div>
          <div class="video-container">
            <video id="wechat-video" controls class="fill">
              <source src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/img/wechat.mp4" type="video/mp4">
            </video>
          </div>
        </div>
      </div>
      
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';

export default {
  // name: '展会介绍',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  metaInfo: {
    title: 'Shenzhen International Furniture Exhibition | Venue & Travel Info', // set a title
    meta: [
      { name: 'keyWords', content: 'Furniture Fair,Home Design,Furniture Exhibitor,Home Furnishing Forum,Design Week,Home Design Week' },
      { name: 'description', content: 'Gather All Information Regarding the Venue, Accommodation, Translation Services, and 144-Hour Visa-Free Transit Policy, Pertaining to the SZ Creative Week.'},
    ],
  },
  data() {
    return {
      current: 'traffic',
    }
  },
  created() {
    let {href, origin} = window.location;
    console.log(window.location);
  },
  destroyed () {
    this.removeVideo('#alipay-video');
    this.removeVideo('#wechat-video');
  },
  methods: {
    removeVideo(elementId) {
      let videoElement = document.querySelector(elementId);
      if (videoElement) {
        // 销毁 VIDEO 元素
        videoElement.pause(); // 停止播放
        videoElement.removeAttribute('src'); // 清空srcs属性来停止加载
        videoElement.load(); // 调用 load() 方法，以确保所有相关数据都被卸载。
        // 将 VIDEO 元素的事件监听器设为 null，以避免内存泄漏。
        videoElement.oncanplaythrough = null;
        videoElement.onerror = null;
        videoElement.remove();
        videoElement = null; // 最后将 VIDEO 元素赋值为 null，以释放其占用的内存。
      }
    }
  },
}
</script>

<style scoped lang="less">
// 移动端
@media screen and (max-width: 1023px) {
  .content-container {
    width: 375px;
    margin: 0 auto 20px;
    
    .section {
      width: 365px;
      margin: 0 auto 20px;
      padding: 20px 10px;
      font-size: 13px;
      
      h1 {
        font-size: 18px;
        text-align: center;
        font-family: 'Poppins-Medium';
        margin-bottom: 10px;
      }
      h2 {
        font-size: 18px;
        text-align: center;
        font-family: 'Poppins-Medium';
        margin-bottom: 10px;
      }
      
      .tips {
        margin: 10px 0 30px;
      }
      
      .row {
        margin: 5px 0;
      }
      .end-row {
        margin-bottom: 15px;
      }
      
      img {
        width: 100%;
        height: auto;
        margin: 10px auto;
      }
      
      .title {
        margin-right: 2px;
        font-size: 14px;
      }
      
      .metro-map {
        display: block;
        width: 70%;
        height: auto;
        margin: 5px auto;
      }
      
      a {
        color: #409eff;
      }
      
      .video-container {
        width: 100%;
        height: 200px;
        background-color: #fff;
        border-radius: 4px;
        overflow: hidden;
        margin-bottom: 12px;
      }
      
      .button {
        font-family: 'Poppins-Medium';
        display: block;
        background-color: #E4007F;;
        color: #fff;
        border: none;
        width: 150px;
        padding: 6px 0;
        margin: 10px auto;
        text-align: center;
        border-radius: 4px;
      }
      
    }
    
  }
  
}

// PC
@media screen and (min-width: 1024px) {
  
  .content-container {
    width: 210px;
    margin: 0 auto 20px;

    .section {
      width: 100%;
      margin: 0 auto 5px;
      padding: 10px 0;
      font-size: 3px;
      
      h1 {
        font-size: 5px;
        text-align: center;
        font-family: 'Poppins-Medium';
        margin-bottom: 5px;
      }
      h2 {
        font-size: 5px;
        text-align: center;
        font-family: 'Poppins-Medium';
        margin-bottom: 5px;
      }
      
      .tips {
        margin-bottom: 10px;
      }
      
      .row {
        margin-bottom: 1px;
      }
      .end-row {
        margin-bottom: 4px;
      }
      .end-row1 {
        font-size: 2.4px;
        font-style: italic;
      }
      
      .title {
        margin-right: 2px;
      }
      
      img {
        width: 100%;
        height: auto;
        margin: 5px auto;
      }
      
      .metro-map {
        display: block;
        width: 70%;
        height: auto;
        margin: 5px auto;
      }
      
      a {
        color: #409eff;
      }
      
      .video-row {
        display: flex;
        justify-content: space-between;
      }
      
      .video-container {
        width: 100px;
        height: 56px;
        background-color: #fff;
        border-radius: 1px;
        overflow: hidden;
      }
      
      .button {
        font-family: 'Poppins-Medium';
        display: block;
        background-color: #E4007F;;
        color: #fff;
        border: none;
        width: 50px;
        padding: 2px 0;
        margin: 6px 0;
        font-size: 4px;
        text-align: center;
        border-radius: 1.2px;
        cursor: pointer;
      }
      
    }
    
  }
  
}
</style>